
import ApiService from "@/core/services/ApiService";

import Quill from "quill";
import Dropdown2 from "@/components/dropdown/Dropdown2.vue";
import PostWidget from "@/components/widgets/feeds/Widget1.vue";
import HacktivityWidget from "@/components/widgets/feeds/Widget6.vue";
import {defineComponent, computed, ref, onMounted, watch, onBeforeMount} from "vue";
import { useStore } from "vuex";
import moment from "moment";
import { Actions } from "@/store/enums/StoreEnums";
import {useRoute} from "vue-router";

export default defineComponent({
  name: "feed-view-component",
  props: {
    id: Number,
  },
  data() {
    return {
       vpn: {
        ip: "",
        online: false
        }
    }
    },
    components: {
        PostWidget,
        HacktivityWidget,
    },
    setup(props) {
      const route = useRoute();
      const store = useStore();
      const scoreboard = ref([]);
      const loading = ref(false)
      const feeds = ref({})
      const id = ref(route.params.id)

       onBeforeMount(async () => {
         loading.value = true
         try {
           await store.dispatch(Actions.FEED_SHOW, id.value);
           feeds.value = store.getters.getShowFeed
         }catch (e) {
           console.log(e)
         }finally {
           loading.value = false
         }
       })
      const user = computed(() => {
        return store.getters.currentUser;
      });

      watch(() => route.params.id, async (newId, oldId) => {
         if (newId !== oldId) {
           loading.value = true
           feeds.value = {}
           try {
             await store.dispatch(Actions.FEED_SHOW, newId);
             feeds.value = store.getters.getShowFeed
           }catch (e) {
             console.log(e)
           } finally {
             loading.value = false
           }
         }
      })
        
      return {
        user,
        scoreboard,
        moment,
        feeds,
        store,
        loading,
      };
    },
});
